import React from 'react'
import classes from './Maincomps.module.css'
import Maincomp from '../Maincomps/Maincomp/Maincomp'

const maincomps = () => {
  return (
    <div className={classes.Maincomps}>
      <Maincomp />
    </div>
  )
}

export default maincomps
