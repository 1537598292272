import React from 'react'
import classes from './MyCV_Comp.module.css'

const mycv_comp = () => {
  return (
    <div className={classes.AboutMe}>
      <div className={classes.SideBar}></div>
      <div className={classes.PhotoSection}>
        <div className={classes.Myphoto}>
          <img
            src={require('../../../assets_/images/my_new_pic.png')}
            alt='hello'
          />
        </div>
        <div className={classes.MyphotoTitle}>
          <h4>MD. SHAKIL CHOWDHURY</h4>

          <h5>MERN STACK DEVELOPER</h5>
          <p>codershakil@gmail.com</p>
        </div>
      </div>
      <div className={classes.JobStatus}>
        <h3>MEMBER: </h3>
        <p>• Metal Leaf Bd Ltd</p>
        <br />
        <h3>COFOUNDER : </h3>
        <p>• Chattogram Darul Quran Ideal Madrasha</p>
        <br />
        <h3>OWNER : </h3>
        <p>• iStudyJobs.com</p>
        <br />

        <h3>CERTIFICATES:</h3>
        <p>• MCSD</p>
        <br />
        <h3>MSS</h3>
        <p>• Sociology : 2015</p>
      </div>
      <div className={classes.Profile}>
        <h3>PERSONAL DETAILS:</h3>
        <p>• Father’s Name : Md. Jamir Uddin </p>
        <p>• Mother’s Name : Afroza Begum</p>
        <p>• Date of Birth : 03 May 1991</p>
        <p>• Religion :Islam</p>
        <p>• Permanent Address : village-Banigram, Post Office-Banigram.</p>
        <p>• Police Station- Banskhali, District –Chittagong.</p>
        <p>• Nationality : Bangladeshi by birth </p>
        <p>• Marital Status : Single</p>
      </div>
    </div>
  )
}

export default mycv_comp
