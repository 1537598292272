import React from 'react'
import { Link } from 'react-router-dom'
import classes from './Nav.module.css'
import { FaArchway, FaUserTie } from 'react-icons/fa'

const nav = () => {
  return (
    <div>
      <div className={classes.divSecureM4}>
        <Link className={classes.SECUREM4} to='/'>
          iStudyJobs.com
        </Link>
      </div>
      <div className={classes.wrapNavUI}>
        <ul className={classes.navUl}>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/jobs'>Jobs</Link>
          </li>
          <li>
            <Link to='/'>Study</Link>
          </li>
          <li style={{ border: '1px solid #6c758f', borderRadius: '5px' }}>
            <Link to='/abouteMe'>
              <span> About Me</span>{' '}
              <span>
                <FaUserTie style={{ color: '#6c758f', fontSize: '20px' }} />
              </span>
            </Link>
          </li>
          <li>
            <Link to='/'>
              ...
              <FaArchway style={{ color: '#6c758f', fontSize: '20px' }} />
              ...
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default nav
