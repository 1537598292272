import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Navs from '../src/components/Navs/Navs'
import Maincomps from '../src/components/Maincomps/Maincomps'
import MFooter from '../src/components/Footer/Footer'
import MyCV from './components/MyCV/MyCV'
import Jobs from './components/Jobs/Jobs'

function App() {
  return (
    <Router>
      <div className='App'>
        <Navs />
        <Routes>
          <Route exact path='/' element={<Maincomps />} />
          <Route exact path='/abouteMe' element={<MyCV />} />
          <Route exact path='/jobs' element={<Jobs />} />
        </Routes>
        {/*  */}

        {/* <AboutMes /> */}
        {/* <Maincomps />*/}
        <MFooter />
      </div>
    </Router>
  )
}

export default App
