import React from 'react'
import classes from './Footer.module.css'

const footer = () => {
  return (
    <div className={classes.Footer}>
      <h3>All rights reserved || 2022</h3>
    </div>
  )
}

export default footer
