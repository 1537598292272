import React from 'react'
import classes from './Jobs.module.css'
import JobSpan from './Job/Job'

const jobs = () => {
  return (
    <div className={classes.Jobs}>
      <JobSpan />
      <JobSpan />
      <JobSpan />
      <JobSpan />
    </div>
  )
}

export default jobs
