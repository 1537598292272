import React from 'react'
import classes from './Maincomp.module.css'

const maincomp = () => {
  return (
    <div className={classes.Maincomp}>
      <div className={classes.divp36187d}>
        <p className={classes.p36187d}>প্রস্তুতি কর্মসংস্থান একসাথে ...</p>
      </div>
      <div>
        <div className={classes.divp0196e3}>
          <p className={classes.p0196e3}>বাংলা ইংরেজি সাধারণ জ্ঞান</p>
        </div>
        <div className={classes.divp36187d_L}>
          <div style={{ borderBottom: '2px solid blue' }}>
            <p className={classes.p36187d_L}>
              এই মুহুর্তের সরকারী চাকরীগুলো ...
            </p>
          </div>
          <div>
            <p className={classes.p36187d_L}>
              <span>বাংলাদেশ সড়ক পরিবহন কর্পোরেশন নিয়োগ</span>
              <span>তথ্য অধিদপ্তর (পিআইডি) নিয়োগ</span>
              <span>কর কমিশনারের কার্যালয়ে নিয়োগ</span>
              <span>বাংলাদেশ স্থলবন্দর কর্তৃপক্ষ নিয়োগ</span>
              <span>বাংলাদেশ রেলওয়ে নিয়োগ</span>
              <span>পরিসংখ্যান ব্যুরো নিয়োগ</span>
              <span>বাংলাদেশ লোক ও কারুশিল্প ফাউন্ডেশন নিয়োগ</span>
              <span>পেট্রোবাংলা চাকরির নিয়োগ</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default maincomp
