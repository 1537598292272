import React from 'react'
import classes from './Job.module.css'

const job = (props) => {
  return (
    <>
      <div className={classes.Maindiv}>
        <div className={classes.Header_Price}>
          <div className={classes.Heading}>
            <h1>নৌপরিবহন অধিদপ্তর</h1>
          </div>
          <div className={classes.Price}>
            <h3> পদ সংখ্যা : ২৬ টি</h3>
          </div>
        </div>
        <div className={classes.Details}>
          <p>
            Hello Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Phasellus cursus, lacus at fringilla lacinia, leo leo eleifend
            tortor, quis sollicitudin mauris ipsum a elit. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Phasellus cursus, lacus at
            fringilla lacinia, leo leo eleifend tortor, quis sollicitudin mauris
            ipsum a elit. Lorem ipsum dolor sit amet, consectetur adipiscing
          </p>
        </div>
      </div>
    </>
  )
}

export default job
