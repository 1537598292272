import React from 'react'
import Nav from '../Navs/Nav/Nav'
import classes from './Navs.module.css'

const navs = () => {
  return (
    <div className={classes.Navs}>
      <Nav />
    </div>
  )
}

export default navs
